import React, { useState, useRef } from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import c from 'classnames';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { timerFilters } from '../../../config';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';

interface Props {
    onChange?: (a: string[] | null | undefined) => void;
}

const { RangePicker } = DatePicker;

const TimerFilter: React.FC<Props> = ({ onChange }) => {
    const currentLocale = intl.getInitOptions().currentLocale === 'en';
    const refList = useRef<any>([]);
    const [activeKey, setActiveKey] = useState<string>('all');
    const [selectTimer, setSelectTimer] = useState<any>(null);

    /**
     * 时间格式
     */
    const timerFormat = 'YYYY-MM-DD HH:mm';

    const onTimerChange = (values: any, formatString?: [string, string]) => {
        setSelectTimer(values);
        if (values) {
            setActiveKey('timer');
            onSelectClick('timer', formatString);
        } else {
            onSelectClick('timer', values);
        }
    };

    /**
     * 查询时间
     * @param value
     * @returns
     */
    const getTimeOfDayTransform = (value: any) => {
        if (value) {
            return [
                dayjs(value[0]).startOf('day').format(timerFormat),
                dayjs(value[1]).endOf('day').format(timerFormat),
            ];
        } else {
            return null;
        }
    };

    /**
     * 点击选择
     * @param v
     */
    const onSelectClick = (v: string, dates?: string[] | null) => {
        if (v !== 'timer') {
            setSelectTimer(null);
        }
        setActiveKey(v);
        switch (v) {
            case 'all':
                onChange?.(null);
                break;
            case 'today':
                const today = dayjs();
                const todaytimer = getTimeOfDayTransform([today, today]);
                onChange?.(todaytimer);
                break;
            case 'sevenDay':
                const sevenDayTimer = getTimeOfDayTransform([dayjs().add(-6, 'day'), dayjs()]);
                onChange?.(sevenDayTimer);
                break;
            case 'timer':
                onChange?.(dates);
                break;
            default:
                onChange?.(null);
                break;
        }
    };

    return (
        <div className={styles.timerFilter}>
            <ul className={styles.filterList}>
                {timerFilters.map((item, index: number) => {
                    const { value, label, alias } = item;
                    const active = value === activeKey;
                    if (value === 'timer') {
                        return (
                            <li
                                key="timer"
                                ref={(node) => (refList.current[index] = node)}
                                className={c({ [styles.timerLi]: true, [styles.activeItem]: active })}
                            >
                                <ConfigProvider locale={intl.getInitOptions().currentLocale === 'en' ? enUS : zhCN}>
                                    <RangePicker
                                        showTime
                                        value={selectTimer}
                                        format={timerFormat}
                                        className={styles.timer}
                                        onChange={onTimerChange}
                                        getPopupContainer={() => refList.current[index]}
                                        placeholder={[
                                            intl.get('Analyze.StartDate').d('开始日期'),
                                            intl.get('Analyze.EndDate').d('结束日期'),
                                        ]}
                                    />
                                </ConfigProvider>
                            </li>
                        );
                    } else {
                        return (
                            <li
                                className={c(
                                    styles.filterItem,
                                    currentLocale ? styles.filterItemEn : null,
                                    active ? styles.activeItem : null,
                                )}
                                key={value || label}
                                onClick={() => onSelectClick(value)}
                            >
                                {intl.get(label).d(alias)}
                            </li>
                        );
                    }
                })}
            </ul>
        </div>
    );
};

export default TimerFilter;
