import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

interface Props {
    data: any;
    activeTab: number;
    titleKey?: string;
}

const NavLineItem: React.FC<Props> = ({ data = {}, activeTab, titleKey = 'title' }) => {
    return (
        <span className={classnames(styles.lineItem, { [styles.active]: activeTab === data.index })}>
            {data[titleKey]}
        </span>
    );
};

export default NavLineItem;
