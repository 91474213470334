import React from 'react';
import { Form } from 'antd';
import SymbolFilter from '../components/SymbolFilter';
import TimerFilter from '../components/TimerFilter';
import TrendFilter from '../components/TrendFilter';
import TitleFilter from '../components/TitleFilter';
import dayjs from 'dayjs';
import type { FormDataProps } from '../types';
import styles from './index.module.less';

interface Props {
    symbolList?: any[];
    inSearch?: boolean;
    onChange: (a: FormDataProps) => void;
}

const SearchItems: React.FC<Props> = ({ symbolList = [], inSearch, onChange }) => {
    const [form] = Form.useForm();
    const timerSecondFormat = 'YYYY-MM-DD HH:mm:ss';

    const onValuesChange = (_v: any, data: FormDataProps) => {
        const { trend, timer = [], ...restData } = data;
        const timerObj =
            timer && timer.length !== 0
                ? {
                      start_time: dayjs(timer[0]).format(timerSecondFormat),
                      end_time: dayjs(timer[1]).format(timerSecondFormat),
                  }
                : {};
        onChange?.({ ...restData, ...trend, ...timerObj });
    };

    return (
        <div className={styles.searchItems}>
            <Form form={form} colon className={styles.form} onValuesChange={onValuesChange}>
                <Form.Item name="symbol_type" className={styles.trend} style={{ width: '28%' }}>
                    <SymbolFilter list={symbolList} inSearch={inSearch} />
                </Form.Item>

                <Form.Item name="trend" className={styles.trend} style={{ width: '30%' }}>
                    <TrendFilter inSearch={inSearch} />
                </Form.Item>

                <Form.Item name="timer" className={styles.timer} style={{ width: '30%' }}>
                    <TimerFilter inSearch={inSearch} />
                </Form.Item>
                {!inSearch && (
                    <Form.Item name="title" className={styles.title} style={{ width: '12%' }}>
                        <TitleFilter />
                    </Form.Item>
                )}
            </Form>
        </div>
    );
};

export default SearchItems;
