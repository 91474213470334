import React, { useState, useEffect } from 'react';
import c from 'classnames';
import { useAppSelector, useAppDispatch } from '@/store/hook';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { setAnalystKey } from '@/store/config';

interface Props {
    name: string;
    left?: boolean;
    right?: boolean;
    dropkey: string;
    callback?: boolean;
    list?: any[];
    props?: string[];
    defaultValue?: string[];
    inSearch?: boolean;
    onChange?: (a?: any, call?: any) => void;
    dropRender?: () => React.ReactNode;
}

const DropPicker: React.FC<Props> = ({
    name,
    left,
    right,
    dropkey,
    callback,
    list = [],
    props = [],
    defaultValue = [],
    inSearch,
    onChange,
    dropRender,
}) => {
    // 展开下拉
    const [open, setOpen] = useState<boolean>(false);
    const [childList, setChildList] = useState<any[]>([]);
    const [activeKey, setActiveKey] = useState<string[]>(defaultValue);
    const [activeLabel, setActiveLabel] = useState<string[]>([]);

    const [activeItem, setActiveItem] = useState<any>({});

    const [activeItemKeys, setActiveItemKeys] = useState<any>({});

    const dispatch = useAppDispatch();

    const currentLocale = intl.getInitOptions().currentLocale;

    const { topHeaderHeight: topHeight, analystKey } = useAppSelector((state: any) => state.config);

    const getNewActiveLabel: any = (l: any[], i: number) => {
        let oplist = [];
        const { label, children = [] } = l.find((item: any) => item.value === activeKey[i]) || {};
        if (label) {
            oplist.push(label);
        }

        if (children.length !== 0) {
            oplist = oplist.concat(getNewActiveLabel(children || [], i + 1));
        }
        return oplist;
    };

    useEffect(() => {
        const { children = [] } = list.find((item) => activeKey.includes(item.value)) || {};
        setChildList(children || []);
        setActiveLabel(getNewActiveLabel(list, 0));
    }, [currentLocale]);

    useEffect(() => {
        if (analystKey !== dropkey) {
            setOpen(false);
        }
    }, [dropkey, analystKey]);

    /**
     * 展开、关闭
     */
    const onChangeOpen = (_event: any, t?: boolean) => {
        if (open) {
            t && onChange?.(activeItem, callback ? setOpen : undefined);
            if (!callback) {
                setOpen(!open);
            }
        } else {
            dispatch(setAnalystKey(dropkey));
            setOpen(!open);
        }
    };

    const onSelectItem = (item: any, i: number) => {
        const { value, label, prop, children = [] } = item || {};
        const key = props[i] || prop;

        if (i === 0) {
            setActiveKey([value]);
            setActiveLabel([label]);
            const newList = (children || []).map((child: any) => ({ ...child, prop }));
            setChildList(newList);
            setActiveItem({ [key]: value });

            setActiveItemKeys([]);
        } else {
            activeKey[i] = value;
            activeLabel[i] = label;
            setActiveItem({ ...activeItem, [key]: value });
            setActiveKey(activeKey);
            setActiveLabel(activeLabel);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div
                className={c({
                    [styles.dropBtn]: true,
                    [styles.dropBtnOpen]: open,
                    [styles.dropBtnLeft]: left,
                    [styles.dropBtnRight]: right,
                    [styles.searchBtn]: inSearch,
                })}
                onClick={(event) => onChangeOpen(event, true)}
            >
                <span className={styles.dropName}>{activeLabel.length !== 0 ? activeLabel.join('/') : name}</span>
                {open ? <span className={styles.botTriangle}></span> : <span className={styles.topTriangle}></span>}

                <span className={styles.left}></span>
                <span className={styles.right}></span>
            </div>
            {open && (
                <div className={styles.dropMenu} style={{ height: `calc(100vh - ${topHeight + 87}px)` }}>
                    <div className={styles.dropList}>
                        {dropRender ? (
                            dropRender()
                        ) : (
                            <>
                                <ul
                                    className={c({
                                        [styles.list]: true,
                                        [styles.muitl]: childList.length !== 0,
                                        [styles.mts]: currentLocale !== 'zh-CN',
                                    })}
                                >
                                    {list.map((item) => {
                                        return (
                                            <li
                                                key={item.value}
                                                className={c({ [styles.active]: item.value === activeKey[0] })}
                                                onClick={() => onSelectItem(item, 0)}
                                            >
                                                <span>{item.label}</span>
                                                {item.value === activeKey[0] && childList.length === 0 && (
                                                    <span className={c(styles.icon, 'iconfont', 'icon-tick')}></span>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                                {childList.length !== 0 && (
                                    <ul className={styles.list}>
                                        {childList.map((item) => {
                                            return (
                                                <li
                                                    key={item.value}
                                                    className={c({ [styles.active]: item.value === activeKey[1] })}
                                                    onClick={() => onSelectItem(item, 1)}
                                                >
                                                    <span>{item.label}</span>
                                                    {item.value === activeKey[1] && (
                                                        <span className={c(styles.icon, 'iconfont', 'icon-dui')}></span>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </>
                        )}
                    </div>
                    <div className={styles.dropModal} onClick={(event) => onChangeOpen(event, true)} />
                </div>
            )}
        </div>
    );
};

export default DropPicker;
