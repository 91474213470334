import React from 'react';

import TransferCom from '@/components/public/TransferCom';

// import IpadAnalystList from './ipad';
import MAnalystList from './m';
import PcAnalystList from './pc';

interface IProps {
    list: any[];
    defaultId?: string | number;
    loader?: React.ReactNode;
    onSelect?: (a: any) => void;
}
const AnalyzeList: React.FC<IProps> = (props) => {
    return (
        <TransferCom
            m={<MAnalystList {...props} />}
            ipad={<PcAnalystList {...props} />}
            pc={<PcAnalystList {...props} />}
        />
    );
};

export default AnalyzeList;
