import React from 'react';
import { ConfigProvider, Form } from 'antd';
import TimerFilter from '../components/TimerFilter';
import TrendFilter from '../components/TrendFilter';
import TitleFilter from '../components/TitleFilter';
import dayjs from 'dayjs';
import type { FormDataProps } from '../types';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import c from 'classnames';

interface Props {
    onChange: (a: FormDataProps) => void;
}

const SearchItems: React.FC<Props> = ({ onChange }) => {
    const currentLocale = intl.getInitOptions().currentLocale === 'en';
    const [form] = Form.useForm();
    const timerSecondFormat = 'YYYY-MM-DD HH:mm:ss';
    const onValuesChange = (_v: any, data: FormDataProps) => {
        const { trend, timer = [], ...restData } = data;
        const timerObj =
            timer && timer.length !== 0
                ? {
                      start_time: dayjs(timer[0]).format(timerSecondFormat),
                      end_time: dayjs(timer[1]).format(timerSecondFormat),
                  }
                : {};
        onChange?.({ ...restData, ...trend, ...timerObj });
    };

    return (
        <div className={c(styles.searchItems, currentLocale ? styles.searchItemsEn : null)}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#2c36de',
                        colorBorder: '#e5e5e5;',
                        // controlHeightLG: 48,
                        colorText: '#262f2d',
                        colorTextPlaceholder: '#666',
                        borderRadius: 4,
                    },
                    // components: {
                    //     Select: {
                    //         colorTextPlaceholder: '#666',
                    //     },
                    //     DatePicker: {
                    //         colorTextPlaceholder: '#666',
                    //     },
                    // },
                }}
            >
                <Form form={form} colon={false} className={styles.form} onValuesChange={onValuesChange}>
                    <Form.Item
                        labelCol={{ style: currentLocale ? { width: 82, marginRight: 8 } : {} }}
                        label={<span>{intl.get('Analyze.TimeRange').d('时间范围')}</span>}
                        name="timer"
                        className={styles.timer}
                    >
                        <TimerFilter />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ style: currentLocale ? { width: 42, marginRight: 8 } : {} }}
                        label={intl.get('Analyze.Trend').d('趋势')}
                        name="trend"
                        className={styles.trend}
                    >
                        <TrendFilter />
                    </Form.Item>

                    <Form.Item name="title" className={styles.title}>
                        <TitleFilter />
                    </Form.Item>
                </Form>
            </ConfigProvider>
        </div>
    );
};

export default SearchItems;
