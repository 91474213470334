import React from 'react';
import c from 'classnames';
import Link from 'next/link';
import styles from './index.module.less';

interface Props {
    onChange?: (a?: string) => void;
}

const TitleFilter: React.FC<Props> = () => {
    return (
        <Link href="/analyzeNew/search">
            <span className={c(styles.icon, 'iconfont', 'icon-sousuo')}></span>
        </Link>
    );
};

export default TitleFilter;
